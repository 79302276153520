<!-- 问答记录  -->
<template>
  <div style="margin-top: 20px;">
    <div class="box-card" style="text-align: left">
      <!-- 查询表单 -->
      <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
        @submit.native.prevent>
        <el-form-item label="问题：">
          <el-input placeholder="请输入问题" v-model="queryForm.questions" @keyup.enter.native="onSubmit"></el-input>
        </el-form-item>
        <el-form-item label="答案：">
          <el-input placeholder="请输入答案" v-model="queryForm.answers" @keyup.enter.native="onSubmit"></el-input>
        </el-form-item>
        <el-form-item>
          <lbButton icon="chaxun" :fill="true" @click=" onSubmit()">查询</lbButton>
        </el-form-item>
      </el-form>
      <le-card title="问答记录">
        <!-- 主要内容 -->
        <div>
          <!-- 表格 -->
          <!-- 使用插件生产表格 -->
          <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @size-change="sizeChange" @current-change="currentChange">
          </avue-crud> -->
          <el-table class="tablestyle" ref="multipleTable" :data="tableData" align="center" header-align="center"
            tooltip-effect="dark" border stripe>
            <el-table-column prop="questions" label="问题"></el-table-column>
            <el-table-column prop="answers" label="答案" width="1200">
              <template slot-scope="scope">
                <template v-if="scope.row.answer_type === 2">
                  <a href="javascript:;" @click=" lookingPicLog(scope.row.answers)" class="aBtn">查看图片</a>
                </template>
                <template v-else>
                  {{ scope.row.answers }}
                </template>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @size-change="sizeChange" @current-change="currentChange" :current-page="queryForm.PageIndex"
            :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="page.total"></el-pagination>
        </div>
      </le-card>
    </div>
    <!-- 查看图片聊天记录弹框 -->
    <lebo-dialog append-to-body title="查看聊天记录" :isShow="showPicLog" width="20%" @close="showPicLog = false" footerSlot
      closeOnClickModal>
      <el-image fit="fill" :src="getFirstImage(showPicSrc)" style="width:100%;" :z-index="10000"
        @click="onpenImage(getFirstImage(showPicSrc))">
        <div slot="error" class="image-error">
          <i class="iconfont lebo-picerr"></i>
          <span>加载失败</span>
        </div>
      </el-image>
      <cusImage v-if="showImage" @close="showImage = false" :url-list="formatImage(getFirstImage(showPicSrc))"
        style="width:100%;">
      </cusImage>
    </lebo-dialog>
  </div>
</template>

<script>
import { getAIChatPageList } from '@/api/maintenanceCenter'
import cusImage from '../components/index.vue'
export default {
  name: 'FAQRecord',
  components: { cusImage },
  props: {},
  data () {
    return {
      // 查询表单
      queryForm: {
        questions: '',
        answers: '',
        PageSize: 10,
        PageIndex: 1
      },
      // 表格数据
      tableData: [],
      showPicLog: false,
      showPicSrc: '',
      showImage: false,
      urlList: [],
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        // searchBtn:false,
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: false,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '问题',
            prop: 'questions'
          }, {
            label: '答案',
            prop: 'answers',
            width: 1200
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  watch: {},
  computed: {},
  methods: {
    // 获取问答记录列表
    async fngetAIChatPageList () {
      const res = await getAIChatPageList(this.queryForm)
      console.log('获取问答记录列表', res)
      this.tableData = res.Data.DataList
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fngetAIChatPageList()
    },
    // 每页条数改变
    sizeChange (val) {
      this.page.pageSize = val
      this.queryForm.PageSize = val
      this.fngetAIChatPageList()
    },
    // 页码改变
    currentChange (val) {
      this.queryForm.PageIndex = val
      this.page.currentPage = val
      this.fngetAIChatPageList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    lookingPicLog (pic) {
      this.showPicLog = true
      this.showPicSrc = pic
    },
    onpenImage (url) {
      this.urlList = [url]
      this.showImage = true
    }
  },
  created () { },
  mounted () {
    this.fngetAIChatPageList()
  }
}
</script>

<style scoped lang="less">
.el-image {
  /deep/ .image-error {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #ddd;

    span {
      margin-top: 5px;
    }
  }
}

.aBtn {
  color: #01a6fb;
  text-decoration: none;
}
</style>
